<template>
    <div class="pc">





        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <div v-else class="container mt-3">

            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <!-- Filtros -->
                <div class="d-flex">
                    <!-- Filtro actividades -->
                    <div>
                        <div id="nav-drawer">
                            <input id="nav-input" type="checkbox" class="nav-unshown">
                            <label id="nav-open" for="nav-input">
                                <span class="border badge badge-pill badge-light"
                                    style="font-size: 14px; padding: 7px 14px;">
                                    <i class="fal fa-sliders-h"></i> {{ translator.filtrar }} <span
                                        class="badge badge-light">{{ selectActivitiesGroups.length }}</span>
                                </span>
                            </label>
                            <!-- Menú lateral filtros -->
                            <label class="nav-unshown" @click="goFilter()" id="nav-close" for="nav-input"></label>
                            <div id="nav-content">
                                <span class="cerrar-filtros"><label id="nav-closeX" for="nav-input"
                                        style="cursor: pointer;" ref="cerrarInput"><i aria-hidden="true"
                                            class="fa fa-times"></i></label></span>
                                <div class="p-3">
                                    <h3 class="text-center">{{ translator.filtrar }}</h3>
                                    <div v-for="act in activitiesGroups" :key="act.activity_group" class="d-flex">
                                        <input v-model="selectActivitiesGroups" type="checkbox" :id="act.activity_group"
                                            :value="act.activity_group" class="mr-2">
                                        <label :for="act.activity_group">{{ act.activity_group_name }}</label>
                                    </div>
                                    <button v-if="activitiesGroups.length > 0" type="button" @click="goFilter"
                                        class="border btn badge-light btn-sm mt-3 w-100">{{
                                            translator.aplicarfiltres }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Filtro distancia -->
                    <div class="dropdown">
                        <span class="border badge badge-pill badge-light" role="button" data-toggle="dropdown"
                            aria-expanded="false" style="font-size: 14px; padding: 7px 14px;">
                            <i class="fal fa-map-marker-alt"></i> {{ translator.distancia }} <span
                                class="badge badge-light">{{ distance }}Km</span>
                        </span>
                        <div class="dropdown-menu dropdown-menu-left">
                            <div class="px-2">
                                <div class="text-center">{{ translator.distanciaallotjament }}</div>
                                <div class="p-3">

                                    <div class="py-2">
                                        <div class="range-slider">
                                            <div class="range-group">
                                                <input class="range-input" value="1" min="1" max="80" step="1"
                                                    v-model="distance" type="range" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-right">
                                    <button class="btn badge-light btn-sm border" @click="changeDistance(distance)">{{
                                        translator.aplicarfiltres }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="activitiesList.length > 0">
                    <!-- <small>{{ activitiesList.length }} </small> -->
                    <small>{{ onlyCountActivitysCivitatis }} </small>
                    <small v-if="activitiesList.length == 1">{{ translator.activitat | toLower }}</small>
                    <small v-else>{{ translator.activitats | toLower }}</small>
                </div>
            </div>

            <div v-if="isLoadingSearch" class="">
                <div class="preload">
                    <div class="preload-wrapper"></div>
                    <div class="preload-dots" style="height: 50vh">
                        <div class="hollow-dots-spinner">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="activitiesList.length <= 0 && !isPreLoad && !isLoadingSearch" class="container mt-5">
                    <div class="row">
                        <div class="col-12 alert alert-info">
                            {{ translator.senseresultats }}
                        </div>
                    </div>
                </div>

                <div v-else class="row mt-3">
                    <!-- :style="a.activity_id[0] == 'I' ? 'display:none;' : ''" -->
                    <article v-for="a in activitiesList" :key="a.activity_id" class="col-12 col-md-4 mb-2">
                        <!-- Actividades Icnea -->

                        <router-link v-if="a.activity_id[0] == 'I'"
                            :to="{ name: 'GuestActivity', params: { id: a.activity_id }, query: { id: $route.query.id } }"
                            class="card mb-3">

                            <div class="row flex-md-column no-gutters">
                                <div class="col-4 col-md-12">
                                    <img width="355" height="146" :src="a.activity_image" class="card-img-top"
                                        :alt="a.activity_headline" loading="lazy">
                                </div>
                                <div class="col-8 col-md-12 pl-2 pl-md-0">
                                    <div class="d-flex justify-content-end linea-categoria ciudad-precio">
                                        <small v-if="parseInt(a.activity_price) > 0" class="text-muted precio">{{
                                            translator.desde }} {{ a.activity_price | formatPrice }}€</small>
                                        <small v-else class="text-muted precio">{{ translator.gratuit }}</small>
                                    </div>
                                    <div class="">
                                        <p class="text-muted text-uppercase categoria">{{ a._category }}</p>
                                        <h2 class="card-title title-limit-text">{{ a.activity_headline }}</h2>
                                        <p class="card-text limit-text font-weight-light" v-html="a.activity_summary">
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </router-link>

                        <!-- Actividades Civitatis -->
                        <!-- v-if="a.activity_id[0] != 'I'"  -->
                        <a v-if="a.activity_id[0] != 'I'" :href="generateUrl(a)" target="_blank" class="card mb-3">

                            <div class="row flex-md-column no-gutters">
                                <div class="col-4 col-md-12">
                                    <img width="355" height="146" :src="a.activity_image" class="card-img-top"
                                        :alt="a.activity_headline" loading="lazy">
                                </div>
                                <div class="col-8 col-md-12 pl-2 pl-md-0 card-container">
                                    <div class="d-flex justify-content-between linea-categoria ciudad-precio">
                                        <!-- <small class="text-muted categoria">{{ a._category }}</small> -->
                                        <small v-if="parseInt(a.activity_price) > 0" class="text-muted precio">{{
                                            translator.desde }} {{ a.activity_price | formatPrice }}€</small>
                                        <small v-else class="text-muted precio">{{ translator.gratuit }}</small>
                                    </div>
                                    <div class="">
                                        <p class="text-muted text-uppercase categoria">{{ a._category }}</p>
                                        <h2 class="card-title title-limit-text ">{{ a.activity_headline }}</h2>
                                        <p class="card-text limit-text font-weight-light" v-html="a.activity_summary">
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </article>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: 'GuestActivities',

    components: {
        NavbarGuest,
    },

    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'distanceStore', 'activitiesGroups', 'activities', 'selectActivities']),

        onlyCountActivitysCivitatis() {
            return this.activitiesList.filter(activity => !activity.activity_id.startsWith('I')).length;
        }

    },
    async created() {
        this.distance = this.distanceStore;
        try {
            if (this.translator) {
                this.isPreLoad = true;
                await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.getActivitiesGroups();
                this.getActivities();
            } else {
                this.isPreLoad = true;
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
        } catch (error) {
            console.error(error);
        }
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.getActivitiesGroups();
            this.getActivities();
        },
        activities() {
            this.isPreLoad = false
            this.filterList()
        },
    },

    data() {
        return {
            isPreLoad: true,
            isLoadingSearch: false,
            selectActivitiesGroups: [],
            activitiesList: [],
            distance: 5
        };
    },

    methods: {
        async getActivitiesGroups() {
            await this.$store.dispatch("guest/getActivitiesGroups", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, distancia: this.distance });
            this.selectActivitiesGroups = this.selectActivities;

        },
        async getActivities() {
            this.isLoadingSearch = true;
            await this.$store.dispatch("guest/getActivities", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, distancia: this.distance, grups: this.selectActivitiesGroups.toString() });
        },
        changeDistance(d) {
            this.$store.dispatch("guest/changeDistance", { distance: d });
            this.getActivitiesGroups()
            this.getActivities()
            this.isLoadingSearch = true;
        },
        goFilter(click = 'background') {
            this.$store.dispatch('guest/saveSelectActivitiesGroups', { selectActivities: this.selectActivitiesGroups })
            this.filterList();
            //this.$refs.navInput.click();
            click != 'background' ? document.querySelector('#nav-input').checked = false : null
        },
        filterList() {
            let list = []

            if (this.activities.length != 0) {

                this.activities.forEach(act => {

                    for (const i of this.activitiesGroups) {
                        i.activity_group == act.activity_group ? act._category = i.activity_group_name : null;
                    }

                    // Filtramos si tenemos grupos seleccionados
                    if (this.selectActivitiesGroups.length > 0) {
                        for (const i of this.selectActivitiesGroups) {
                            i == act.activity_group ? list.push(act) : null;
                        }
                    } else {
                        list.push(act)
                    }
                });

            }

            this.activitiesList = list;
            this.isLoadingSearch = false;
        },
        generateUrl(activity) {
            //Actividad Civitatis
            return `${activity.activity_url}?ag_aid=${activity.activity_code}&ag_cmp=icnea`
        }
    },
    filters: {
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        },
        formatPrice(value) {
            let formattedNumber = parseFloat(value).toFixed(2);
            formattedNumber.endsWith('.00') ? formattedNumber = formattedNumber.slice(0, -3) : null;
            return formattedNumber;
        }
    },

}
</script>

<style scoped>
.pc {
    background-color: transparent;
}

h2 {
    font-size: 16px;
    letter-spacing: 0;
}

.card {
    background-color: transparent;
}

.card:hover {
    text-decoration: none;
    cursor: pointer;
}

.card-img-top {
    object-fit: cover;
}

.linea-categoria {
    font-size: 12px;
}

.card-text {
    font-size: 14px;
}

@media (min-width: 768px) {
    .card-body {
        padding: 1rem 0;
    }

    .ciudad-precio {
        position: absolute;
        top: -25px;
        right: 5px;
    }

    .ciudad-precio .precio,
    .ciudad-precio .categoria {
        font-size: 14px;
        font-weight: bold;
        background-color: #FFF;
        color: #212121 !important;
        padding: 0 5px;
        border-radius: 10px;

    }
}


#nav-drawer {
    position: relative;
}

.nav-unshown {
    display: none;
}

#nav-open {
    display: inline-block;
    width: 110px;
    height: 22px;
    /* vertical-align: middle; */
}

#nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
}

#nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 90%;
    max-width: 330px;
    height: 100%;
    background: #fff;
    transition: .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
}

#nav-input:checked~#nav-close {
    display: block;
    opacity: .5;
}

#nav-input:checked~#nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    box-shadow: 6px 0 25px rgba(0, 0, 0, .15);
}

.cerrar-filtros {
    position: absolute;
    right: 10px;
    padding: 10px;
    cursor: pointer;
}

.card-title {
    font-size: 16px;
}

.categoria {
    font-size: 12px;
    padding-bottom: 4px;
    margin-top: 8px;
}

.title-limit-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.limit-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

article {
    transition: all .2s ease-in-out;
}

article:hover {
    transform: scale(1.04);
}


/* input range */
.dropdown-menu {
    width: 260px;
}

@media (max-width: 389px) {
    .dropdown-menu {
        max-width: 190px;
    }
}

.range-slider {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.range-slider .range-group {
    position: relative;
    flex: 0 0 100%;
}

.range-slider .range-group .range-input {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 0;
}

.range-slider .range-group .range-input:focus {
    outline: 0;
}

.range-slider .range-group .range-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    padding: 4px 0;
    cursor: pointer;
    -webkit-animation: 0.2s;
    animation: 0.2s;
    background: rgba(66, 135, 245, 1);
    background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);

    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.range-slider .range-group .range-input::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #3faffa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

.range-slider .range-group .range-input::-moz-range-track {
    width: 100%;
    height: 2px;
    padding: 3px 0;
    cursor: pointer;
    animation: 0.2s;
    background: #3FAFFA;
    background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.range-slider .range-group .range-input::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #3FAFFA;
    cursor: pointer;
}

.range-slider .range-group .range-input::-ms-track {
    width: 100%;
    height: 2px;
    padding: 5px 0;
    cursor: pointer;
    animation: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.range-slider .range-group .range-input::-ms-fill-lower,
.range-slider .range-group .range-input::-ms-fill-upper {
    background: #3FAFFA;
    background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 23%, rgba(66, 135, 245, 1) 27%, rgba(66, 135, 245, 1) 72%, rgba(41, 137, 216, 0) 79%);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.range-slider .range-group .range-input::-ms-thumb {
    z-index: 2;
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #3FAFFA;
    cursor: pointer;
}
</style>