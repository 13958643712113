<template>
  <div :class="id" class="button-background my-3">
    <span :class="`slide-text${id}`" class="slide-text">{{ translator.obrir }} {{ title | maxChar(title) }}</span>
    <div :class="`slider${id} slider`">
      <i :class="`locker${id}`" class="bola locker"><i class="fas fa-chevron-right"></i></i>
    </div>
  </div>
</template>

<script>
const $ = require("jquery");

export default {
  name: 'NavbarGuest',
        props: ['id', 'name', 'numBooking', 'translator', 'userId', 'door'],
  mounted() {
    this.iniciaBoton();
  },
  computed: {
    title() {
      if ( this.name[0] != '') {
        return this.name[0];
      } else {
        return this.name[1];
      }
    }
  },
  methods: {
     iniciaBoton() {
      const self = this
      let initialMouse = 0;
      let slideMovementTotal = 0;
      let mouseIsDown = false;
      let slider = $(`.slider${self.id}`);

      slider.on("mousedown touchstart", function (event) {
        mouseIsDown = true;
        slideMovementTotal = $(`.${self.id}`).width() - $(this).width() + 0;
        initialMouse = event.clientX || event.originalEvent.touches[0].pageX;
      });

      $(document.body, $(`.slider${self.id}`)).on("mouseup touchend", function (event) {
        if (!mouseIsDown) return;
        mouseIsDown = false;
        const currentMouse = event.clientX || event.changedTouches[0].pageX;
        const relativeMouse = currentMouse - initialMouse;

        if (relativeMouse < slideMovementTotal) {
          $(`.slide-text${self.id}`).fadeTo(300, 1);
          slider.animate({ left: "6px" }, 300);
          return;
        }
        slider.addClass("unlocked");
        self.openDoor();

        $(`.locker${self.id}`).html(`
          <div style="display:flex; justify-content: center; align-items:center;">
            <?xml version="1.0" encoding="UTF-8"?>
            <svg width="24px" height="32px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Combined Shape</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M12,0.5 C16.6090673,0.5 20.3613215,4.16845296 20.4962434,8.74483973 L20.5,9 L20.5,9.29682854 C20.5,10.1252557 19.8284271,10.7968285 19,10.7968285 C18.2203039,10.7968285 17.5795513,10.2019405 17.5068666,9.44128855 L17.5,9.29682854 L17.5,9 C17.5,5.96243388 15.0375661,3.5 12,3.5 C9.03652085,3.5 6.62046086,5.84378001 6.5043672,8.77879286 L6.5,9 L6.5,15 L6.494,15.125 L20.5714286,15.125 C22.3994643,15.125 23.8971071,16.5392598 23.9949188,18.315051 L24,18.5 L24,28.625 C24,30.4865234 22.4625,32 20.5714286,32 L20.5714286,32 L3.42857143,32 C1.5375,32 0,30.4865234 0,28.625 L0,28.625 L0,18.5 C0,16.6384766 1.5375,15.125 3.42857143,15.125 L3.42857143,15.125 L3.505,15.125 L3.5,15 L3.5,9 C3.5,4.30557963 7.30557963,0.5 12,0.5 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
            </svg> <span style="color:#FFF; margin-left: 10px; font-size: 24px;">Abierto</span>
            </div>
        `);

        setTimeout(() => {
          $(`.slide-text${self.id}`).fadeTo(300, 1);
          slider.animate({ left: "6px" }, 300);
        }, 2010);

        setTimeout(() => {
          slider.removeClass("unlocked");
          $(`.locker${self.id}`).html("<i class='fas fa-chevron-right'></i>");
          slider.off("click tap");
        }, 2012);

      });

      $(document.body).on("mousemove touchmove", function (event) {
        if (!mouseIsDown) return;

        const currentMouse = event.clientX || event.originalEvent.touches[0].pageX;
        const relativeMouse = currentMouse - initialMouse;
        let slidePercent = 1 - relativeMouse / slideMovementTotal;

        $(`.slide-text${self.id}`).fadeTo(0, slidePercent);

        if (relativeMouse <= 15) {
          slider.css({ left: "6px" });
          return;
        }
        if (relativeMouse >= slideMovementTotal + 0) {
          slider.css({ left: slideMovementTotal + "px" });
          return;
        }
        slider.css({ left: relativeMouse - 10 });
      });
    },

    openDoor() {
      this.$store.dispatch("guest/openDoor", {
        usr: this.userId,
        reserva: this.numBooking,
        pany: this.door,
        ip: '',
        vm : this,
        tObert: 'Open'
      });
    },
  },
  filters: {
    maxChar(value) {
      if (!value) return "";
      let val = value.toString()
      val.length >= 22 ? val = value.substr(0, 22) + "..." : val
        return val;
    },
  },

};
</script>

<style scoped>
.button-background {
  position: relative;
  background-color: #545454;
  width: 278px;
  height: 42px;
  border: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  left: 6px;
  background-color: white;
  width: 34px;
  height: 34px;
  border: 1px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider.unlocked {
  transition: all 0.3s;
  width: inherit;
  left: 0 !important;
  height: inherit;
  border-radius: inherit;
  background: rgb(49,207,80);
  background: linear-gradient(90deg, rgba(49,207,80,1) 0%, rgba(27,188,152,1) 100%);
}
.bola {
  color: #000;
  font-size: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.slide-text {
  color: #FFF;
  font-size: 12px;
  margin-left: 15px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.text-open {
  color: #FFF;
}
</style>