<template>
    <div class="pc">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->
        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <div class="container-fluid p-0" style="position: relative;">
            <router-link v-if="!isPreLoad" :to="{ name: 'GuestActivities', query: { id: $route.query.id } }"
                class="badge badge-pill badge-light btn-volver"><i class="fal fa-chevron-left"></i> {{ translator.tornar
                }}</router-link>

            <div class="container-swiper">
                <div class="swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(img, i) in activityDetail.activity_gallery" :key="i">
                            <img :src="img.activity_gallery_image" :alt="activityDetail.activity_headline"
                                class="img-swiper">
                        </div>
                    </div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div>

            <div class="text-right">
                <span v-if="activityDetail.activity_price == 0"
                    class="badge badge-pill badge-light p-2 precio-responsive"><strong>{{ translator.gratuit
                        }}</strong></span>
                <span v-else class="badge badge-pill badge-light p-2 precio-responsive"><strong>{{ translator.desde }}
                        {{ activityDetail.activity_price | ereaseZero }}€</strong></span>
            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <div class="col-12">
                    <div>
                        <div class="row">
                            <div class="col-8 ">
                                <h1>{{ activityDetail.activity_headline }}</h1>
                            </div>
                            <div class="col-4">
                                <!-- <a href="#" type="button" class="btn btn-secondary" style="max-height:45px;">{{ translator.reservar }}</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row mt-4">

                <!-- Buscador -->
                <div v-if="isIcneaActivity" id="booking" class="col d-none d-md-block mb-5">

                    <div class="row">

                        <!-- Cuando -->
                        <div class="col-6">
                            <label for="cuando" class="">{{ translator.quanvolsanar }}</label>
                            <input id="cuando" type="date" v-model="currentDate" :min="minBookingDay"
                                :max="maxBookingDay" class="form-control">
                        </div>

                        <!-- Sesión -->
                        <div v-if="activityDetail.sessions.length >= 0" class="col-6">
                            <label for="sesion">{{ translator.escullsessio }}</label>
                            <select id="sesion" class="w-100 form-control" v-model="currentSession">
                                <option v-for="session in activityDetail.sessions" :key="session.session_id"
                                    :value="session.session_id">{{ session.session_name }}</option>
                            </select>
                        </div>

                        <!-- Entradas disponibles -->
                        <div v-if="currentSession && currentAvailability && activityDetail.minimumRate"
                            class="accordion mt-3 col-6" id="accordionEntradas">

                            <div class="card">
                                <div class="card-header-exp form-control p-0" id="headingOne">
                                    <button class="btn btn-link btn-block text-left btn-acordeon"
                                        style="display: flex !important; justify-content: space-between;" type="button"
                                        data-toggle="collapse" data-target="#collapseEntradas" aria-expanded="false"
                                        aria-controls="collapseEntradas">
                                        <span>{{ currentAvailability }} {{ translator.disponibles }}</span>
                                        <i class="fas fa-chevron-down icon-down"></i>
                                    </button>
                                </div>

                                <div id="collapseEntradas" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordionEntradas">
                                    <div class="mt-3">
                                        <div v-for="rate in activityDetail.rates" :key='rate.rate_name'
                                            class="d-flex justify-content-between mb-2">
                                            <label :for="rate.rate_name">{{ rate.rate_name }}</label>
                                            <div class="d-flex ml-3">
                                                <button type="button" class="btn-operacion menos"
                                                    @click="decrement(rate.rate_id)">-</button>
                                                <input type="text" class="form-control input-number"
                                                    v-model="currentRates.rates[rate.rate_id]" readonly>
                                                <button type="button" class="btn-operacion mas"
                                                    @click="increment(rate.rate_id)">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Suplementos -->
                        <div class="accordion supplements mt-3 col-6" id="accordionSuplementos"
                            v-if="currentSession && currentAvailability && activityDetail.minimumRate && activityDetail.supplements.length > 0">

                            <div class="card">
                                <div class="card-header-exp form-control p-0" id="headingOne">
                                    <button class="btn btn-link btn-block text-left btn-acordeon"
                                        style="display: flex !important; justify-content: space-between;" type="button"
                                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne">
                                        <span>{{ translator.afegirsuplements }}</span>
                                        <i class="fas fa-chevron-down icon-down"></i>
                                    </button>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordionSuplementos">
                                    <div class="mt-3">
                                        <div v-for="supplement in activityDetail.supplements"
                                            :key='supplement.supplement_name' class="supplement mb-4">
                                            <div class="supplement-headline mb-3">
                                                <h6>{{ supplement.supplement_name }}</h6>
                                            </div>

                                            <div class="supplement-rates d-flex justify-content-between mb-2"
                                                v-for="rate in supplement.rates" :key="rate.rate_name + rate.rate_id">
                                                <label :for="rate.rate_name">{{ rate.rate_name }}</label>

                                                <div class="d-flex ml-3">
                                                    <button type="button" class="btn-operacion menos"
                                                        @click="decrementRate(supplement.supplement_id, rate.rate_id)"
                                                        :disabled="!parseInt(currentRates.rates[rate.rate_id]) || parseInt(currentRates.rates[rate.rate_id]) == 0">-</button>
                                                    <input
                                                        :id="rate.rate_name + supplement.supplement_id + '$' + rate.rate_id"
                                                        type="text"
                                                        class="form-control supplement-input form-control input-number "
                                                        v-model='currentRates.supplements[supplement.supplement_id + "$" + rate.rate_id]'
                                                        readonly>
                                                    <button type="button" class="btn-operacion mas"
                                                        @click="incrementRate(supplement.supplement_id, rate.rate_id)"
                                                        :disabled="!parseInt(currentRates.rates[rate.rate_id]) || parseInt(currentRates.rates[rate.rate_id]) == 0">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row justify-content-end">

                        <div class="details-rate col-12">
                            <div class="datails-totalprice" v-if="currentPrice">
                                <h6>
                                    {{ translator.preutotal }} <span>{{ currentPrice | roundPrice }}</span>
                                </h6>
                            </div>
                        </div>

                        <div v-if="((currentSession && !currentAvailability) || activityDetail.minimumRate === 0)"
                            class="col-12">
                            {{ translator.nodisponibilitat }}
                        </div>
                        <div v-if="currentPrice > 0" class="w-100 mt-3 col-12 d-flex justify-content-end">
                            <div class="btn btn-dark mr-2" style="width: 200px;" @click="addToCart">
                                {{ translator.afegiracomanda }}
                            </div>

                            <div class="btn btn-secondary" style="width: 200px;" @click="bookNow">
                                <span v-if="!isClickInBooking">
                                    {{ translator.reservarara }}
                                </span>
                                <span v-else>
                                    <div class="preload-wrapper"></div>
                                    <div class="preload-dots">
                                        <div class="hollow-dots-spinner" style="margin: auto;">
                                            <div class=" dot" style="border: calc(15px / 5) solid #fff;"></div>
                                            <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                            <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Descripción -->
                <div class="col-12 col-md-12">
                    <div v-html="activityDetail.activity_description"></div>
                </div>

            </div>
            <div class="row mt-5"
                v-if="activityDetail.activity_latitude != '' && activityDetail.activity_longitude != ''">
                <div class="col-12">
                    <h2>{{ translator.ubicacio }}</h2>
                    <p>{{ activityDetail.activity_address }}</p>
                    <div id="map" class="mapa"></div>
                </div>
            </div>
        </div>

        <!-- Botón reserva en responsive -->
        <div v-if="showBookingResponsive"
            style="width: 100%; height: 100vh; position: fixed; top:0; left: 0; z-index:2;"
            @click='showBookingResponsive = !showBookingResponsive'>
        </div>
        <div class="footer-reservation fixed-bottom d-md-none text-center"
            :class="showBookingResponsive ? 'show-booking-responsive' : ''">
            <a class="text-booking border-bottom" @click='showBookingResponsive = !showBookingResponsive'>
                {{ translator.reservar }}
                <i v-if="showBookingResponsive" class="fas fa-chevron-down icon-down"></i>
                <i v-else class="fas fa-chevron-up icon-down"></i>
            </a>

            <!-- Buscador -->
            <div v-if="isIcneaActivity" class="">

                <div class="card card-responsive px-5">

                    <div class="row mt-3">

                        <!-- Cuando -->
                        <div class="col-6">
                            <label for="cuando" class="titular-responsive">{{ translator.quanvolsanar }}</label>
                            <input id="cuando" type="date" v-model="currentDate" :min="minBookingDay"
                                :max="maxBookingDay" class="form-control" style="height:40px;">
                        </div>

                        <!-- Sesión -->
                        <div v-if="activityDetail.sessions.length >= 0" class="col-6">
                            <label for="sesion" class="titular-responsive">{{ translator.escullsessio }}</label>
                            <select id="sesion" class="w-100 form-control" v-model="currentSession"
                                style="height:40px;">
                                <option v-for="session in activityDetail.sessions" :key="session.session_id"
                                    :value="session.session_id">{{ session.session_name }}</option>
                            </select>
                        </div>

                        <!-- Entradas disponibles -->
                        <div v-if="currentSession && currentAvailability && activityDetail.minimumRate"
                            class="accordion mt-3 col-12" id="accordioResponsive">

                            <div class="card card-custom">
                                <div class="card-header-exp form-control" style="height:40px;" id="headingOne">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left btn-acordeon"
                                            style="display: flex !important;" type="button" data-toggle="collapse"
                                            data-target="#collapseEntradas" aria-expanded="false"
                                            aria-controls="collapseEntradas">
                                            <span>{{ currentAvailability }} {{ translator.disponibles }}</span>
                                            <i class="fas fa-chevron-down icon-down"></i>
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseEntradas" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordioResponsive">
                                    <div class="mt-3">
                                        <div v-for="rate in activityDetail.rates" :key='rate.rate_name'
                                            class="d-flex justify-content-between px-3 mb-2">
                                            <label :for="rate.rate_name">{{ rate.rate_name }}</label>
                                            <div class="d-flex ml-3">
                                                <button type="button" class="btn-operacion menos"
                                                    @click="decrement(rate.rate_id)">-</button>
                                                <input type="text" class="form-control input-number"
                                                    v-model="currentRates.rates[rate.rate_id]" readonly>
                                                <button type="button" class="btn-operacion mas"
                                                    @click="increment(rate.rate_id)">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card card-custom"
                                v-if="currentSession && currentAvailability && activityDetail.minimumRate && activityDetail.supplements.length > 0">
                                <div class="card-header-exp form-control" style="height:40px;" id="headingdos">
                                    <h5 style="color:var(--custom-primary);" class="mb-0">
                                        <button class="btn btn-link btn-block text-left btn-acordeon"
                                            style="display: flex !important;" type="button" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne">
                                            {{ translator.afegirsuplements }} <i
                                                class="fas fa-chevron-down icon-down"></i>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingdos"
                                    data-parent="#accordioResponsive">
                                    <div class="mt-3">
                                        <div v-for="supplement in activityDetail.supplements"
                                            :key='supplement.supplement_name' class="supplement mb-4 px-3">
                                            <div class="supplement-headline mb-3">
                                                <h6 class="text-left">{{ supplement.supplement_name }}</h6>
                                            </div>

                                            <div class="supplement-rates d-flex justify-content-between mb-2"
                                                v-for="rate in supplement.rates" :key="rate.rate_name + rate.rate_id">
                                                <label :for="rate.rate_name">{{ rate.rate_name }}</label>

                                                <div class="d-flex ml-3">
                                                    <button type="button" class="btn-operacion menos"
                                                        @click="decrementRate(supplement.supplement_id, rate.rate_id)"
                                                        :disabled="!parseInt(currentRates.rates[rate.rate_id]) || parseInt(currentRates.rates[rate.rate_id]) == 0">-</button>
                                                    <input
                                                        :id="rate.rate_name + supplement.supplement_id + '$' + rate.rate_id"
                                                        type="text"
                                                        class="form-control supplement-input form-control input-number "
                                                        v-model='currentRates.supplements[supplement.supplement_id + "$" + rate.rate_id]'
                                                        readonly>
                                                    <button type="button" class="btn-operacion mas"
                                                        @click="incrementRate(supplement.supplement_id, rate.rate_id)"
                                                        :disabled="!parseInt(currentRates.rates[rate.rate_id]) || parseInt(currentRates.rates[rate.rate_id]) == 0">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-rate col-12 mt-3">
                            <div class="datails-totalprice" v-if="currentPrice">
                                <h6 style="color: #0c2c40">
                                    {{ translator.preutotal }} <span>{{ currentPrice | roundPrice }}</span>
                                </h6>

                            </div>
                        </div>

                        <div v-if="((currentSession && !currentAvailability) || activityDetail.minimumRate === 0)"
                            class="col-12">
                            {{ translator.nodisponibilitat }}
                        </div>
                        <div class="btn-compra col-12" v-if="currentPrice > 0">
                            <div class="btn btn-dark btn-block border text-white" @click="addToCart">
                                {{ translator.afegiracomanda }}
                            </div>
                            <div class="btn btn-secondary btn-block" @click="bookNow">
                                <span v-if="!isClickInBooking">
                                    {{ translator.reservarara }}
                                </span>
                                <span v-else>
                                    <div class="preload-wrapper"></div>
                                    <div class="preload-dots">
                                        <div class="hollow-dots-spinner" style="margin: auto;">
                                            <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                            <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                            <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>



                        </div>
                    </div>

                </div>

            </div>
        </div>


    </div>
</template>


<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";
//import 'v-calendar/lib/v-calendar.min.css';


export default {
    name: 'GuestActivity',

    components: {
        NavbarGuest,
    },

    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'activityDetail']),
        computedAvailability() {
            return this.currentAvailability - this.currentGuests;
        }
    },

    data() {
        return {
            isPreLoad: false,
            isIcneaActivity: false,
            showBookingResponsive: false,
            currentDate: '',
            minBookingDay: '',
            maxBookingDay: '',
            currentSession: '',
            currentAvailability: 0,
            currentGuests: 0,
            currentRates: {
                rates: new Object,
                supplements: new Object
            },
            currentPrice: 0,
            urlBookNow: null,
            error: null,
            isClickInBooking: false,
        };
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.numInstruction = this.$route.params.instruction;
        },
        booking() {
            this.minBookingDay = this.moment(this.booking.arrival_date).format("yyyy-MM-DD")
            this.maxBookingDay = this.moment(this.booking.departure_date).format("YYYY-MM-DD")
            this.currentDate = this.minBookingDay;
            this.$store.dispatch("guest/getActivityDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, id: this.$route.params.id, datefrom: new Date(this.moment(this.booking.arrival_date).format("YYYY-MM-DD")), dateto: new Date(this.moment(this.booking.departure_date).format("YYYY-MM-DD")) });
        },
        activityDetail(newQ) {
            this.isPreLoad = false;

            /*eslint-disable*/
            const map = L.map('map', { gestureHandling: true }).setView([String(this.activityDetail.activity_latitude).replace(',', '.'), String(this.activityDetail.activity_longitude).replace(',', '.')], 14);
            L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }).addTo(map);
            let marker = L.marker([String(this.activityDetail.activity_latitude).replace(',', '.'), String(this.activityDetail.activity_longitude).replace(',', '.')], { draggable: false }).addTo(map);
            /*eslint-enable*/

            String(newQ.activity_id[0]).toLowerCase() === 'i' ? this.isIcneaActivity = true : null;
        },
        currentSession() {
            //if(this.isLoaded) {
            this.availabilityAndMinimumPrice();
            this.calculatePrice();
            //}
        },
        currentDate() {
            //if(this.isLoaded) {
            this.calculatePrice();
            //}
        },
        currentRates: {
            deep: true,
            handler() {
                this.calculatePrice();
            }
        }
    },

    async created() {
        try {
            this.isPreLoad = true;
            if (this.translator) {
                await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                //await this.$store.dispatch("guest/getActivityDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, id: this.$route.params.id, datefrom: this.booking.arrival_date, dateto: this.booking.departure_date });

            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
            //this.$store.dispatch("guest/getActivitatReservaCrear", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, id: this.$route.params.id });          
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        convertToDatePicker() {
            console.log('ya')
            let inputElement = document.getElementById("myInput");
            var newInput = document.createElement("input");
            newInput.type = "date";
            newInput.id = "datePickerInput";
            newInput.value = inputElement.value;
            inputElement.parentNode.replaceChild(newInput, inputElement);

        },
        calculatePrice() {
            this.currentPrice = 0;
            this.currentGuests = 0;


            Object.keys(this.currentRates.rates).forEach(rateId => {
                const rate = this.activityDetail.rates.filter(rate => rate.rate_id == rateId)[0];

                const priceUnit = parseFloat(rate.prices[0].price);
                const units = parseInt(this.currentRates.rates[rateId]);

                this.currentPrice += priceUnit * units;
                this.currentGuests += units;

                if (this.currentRates.rates[rateId] === "0") {
                    this.currentRates.rates[rateId] = 0;

                    Object.keys(this.currentRates.supplements).forEach(supplement => {
                        if (rateId == supplement.split("$")[1]) {
                            this.currentRates.supplements[supplement] = 0;
                        }
                    })
                }
            })
            Object.keys(this.currentRates.supplements).forEach(supplementId => {
                const [supplementID, rateId] = supplementId.split('$');

                this.activityDetail.supplements.forEach(supplement => {
                    if (supplement.supplement_id == supplementID) {
                        const rate = supplement.rates.filter(rate => rate.rate_id == rateId)[0];

                        const priceUnit = parseFloat(rate.prices[0].price);
                        const units = parseInt(this.currentRates.supplements[supplementID + '$' + rateId]);

                        this.currentPrice += priceUnit * units;
                    }
                })
            })

        },
        availabilityAndMinimumPrice() {
            if (this.currentSession) {
                this.currentAvailability = parseInt(this.activityDetail.sessions.filter(session => {
                    return session.session_id == this.currentSession;
                })[0].availabilities[0].availability);
            }
            this.activityDetail.rates.forEach(rate => {
                rate.prices.forEach(prices => {
                    prices.price = parseFloat(prices.price);

                    if (!this.activityDetail.minimumRate || this.activityDetail.minimumRate > prices.price) {
                        this.activityDetail.minimumRate = prices.price
                    }
                })
            })
        },

        async bookNow() {
            this.isClickInBooking = true;
            setTimeout(() => {
                this.isClickInBooking = false;
            }, 4000)
            await this.$store.dispatch("guest/getActivitatReservaCrear", {
                usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                reserva: this.user.user_booking,
                activities: [
                    {
                        usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                        reserva: this.user.user_booking,
                        data: this.currentDate,
                        activitat: this.activityDetail.activity_id,
                        suplement: this.currentRates.supplements,
                        tarifa: this.currentRates.rates,
                        session: this.currentSession,
                        persones: this.currentGuests
                    }
                ]
            })
        },

        makeIdentifier(activity) {
            return `${activity.activity_id}${this.currentDate}${this.currentSession}`;
        },

        addToCart() {

            const article = {
                activity_hash: this.makeIdentifier(this.activityDetail),
                activity_img: this.activityDetail.activity_gallery.length > 0 ? this.activityDetail.activity_gallery[0].activity_gallery_image : '',
                activity_headline: this.activityDetail.activity_headline,
                activity_summary: this.activityDetail.activity_summary,
                activity_total_price: this.currentPrice,
                activity_details: this.currentRates,
                activity: {
                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                    reserva: this.user.user_booking,
                    data: this.currentDate,
                    activitat: this.activityDetail.activity_id,
                    suplement: this.currentRates.supplements,
                    tarifa: this.currentRates.rates,
                    session: this.currentSession,
                    persones: this.currentGuests
                },
            }

            console.log(article)

            let storage = localStorage.getItem("shoppingCart");

            if (storage) {
                storage = JSON.parse(storage).filter(a => {
                    return article.activity_hash != a.activity_hash;
                })
                storage.push(article);

            } else {
                storage = [article];
            }

            localStorage.setItem("shoppingCart", JSON.stringify(storage));
            storage && storage.length > 0 ? this.$store.dispatch("guest/addShoppingCart", { numActivities: storage.length }) : null;

            this.$toast.success(this.translator.afegit);
        },

        increment(rateId) {
            if (this.currentRates.rates[rateId] == undefined || this.currentRates.rates[rateId] == 0) {
                this.$set(this.currentRates.rates, rateId, 0);
            }
            if (this.currentRates.rates[rateId] !== undefined && this.currentRates.rates[rateId] < this.currentRates.rates[rateId] + this.computedAvailability) {
                this.currentRates.rates[rateId]++;
            }
        },
        decrement(rateId) {
            console.log(rateId)
            if (this.currentRates.rates[rateId] !== undefined && this.currentRates.rates[rateId] > 0) {
                this.currentRates.rates[rateId]--;
            }

            // Si las entradas estan ha 0 borro todos los suplementos
            if (this.currentRates.rates[rateId] == 0) {
                if (rateId in this.currentRates.rates) {
                    const supplementKeys = Object.keys(this.currentRates.supplements);

                    supplementKeys.forEach(key => {
                        console.log(key)
                        if (key.endsWith(`$${rateId}`)) {
                            delete this.currentRates.supplements[key];
                        }
                    });
                }
            }
        },
        incrementRate(supplementId, rateId) {
            rateId = rateId || 0; // Si rateId es undefined, inicialízalo a 0
            this.$set(this.currentRates.supplements, supplementId + "$" + rateId, (this.currentRates.supplements[supplementId + "$" + rateId] || 0) + 1);
        },
        decrementRate(supplementId, rateId) {
            rateId = rateId || 0; // Si rateId es undefined, inicialízalo a 0
            this.$set(this.currentRates.supplements, supplementId + "$" + rateId, Math.max((this.currentRates.supplements[supplementId + "$" + rateId] || 0) - 1, 0));
        },

    },

    updated() {
        /*eslint-disable*/
        new Swiper('.swiper', {
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
        /*eslint-enable*/
    },

    filters: {
        ereaseZero(value) {
            if (!value) return "";
            value = String(value.includes(".00")) ? String(value).replace(".00", "") : ''
            return value;
        },
        filterCapitalize(value) {
            if (!value) return "";
            return (value) ? value.trim()[0].toUpperCase() + value.trim().slice(1,) : '';
        },
        roundPrice(value) {
            if (!value) return "";
            return Math.ceil(value).toLocaleString('es-ES') + '€';
        }
    },
}
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css";

.swiper-button-prev,
.swiper-button-next {
    color: #f8f9fa;
}

h2 {
    font-size: 20px;
}

.btn-volver {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
    padding: 0.5rem 1rem;
}

.footer-reservation {
    width: 100%;
    height: 3.5rem;
    border-radius: .5rem .5rem 0 0;
    transition: height .3s;
    padding: 0;
    background-color: var(--colores-azul-claro);
}

.card-responsive {
    position: absolute;
    top: 4em;
    width: 100%;
    height: calc(100% - 85px);

    background-color: #f8f8f8;
    color: #FFF;

}

.card-custom {
    background-color: #FFF;
}

.text-booking {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1em 0;
    text-decoration: none;
    color: #FFF;
    background-color: var(--colores-azul-claro);
    cursor: pointer;
}

.show-booking-responsive {
    height: 85%;
    transition: height .3s;
    display: block;
    overflow-y: auto;
}

.precio-responsive {
    position: relative;
    top: -40px;
    left: -15px;
    z-index: 1;
}

.mapa {
    width: 100%;
    height: 350px;
}

.container-swiper {
    width: 100%;
    height: auto;
}

.swiper {
    width: 100%;
    height: 100%;
}

.img-swiper {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 550px;
}

.input-number {
    width: 60px;
}

.input-number::-webkit-inner-spin-button,
.input-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.form-control[readonly] {
    background-color: transparent;
}

.details-rate {
    width: 100%;
    text-align: right;
}

.btn-operacion {
    border: 1px solid #ced4da;
    height: 31px;
    width: 31px;
    text-align: center;
    color: #495057;
    border-radius: 6px;
}

.mas {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.menos {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.titular-responsive {
    color: #000;
    text-align: left;
    font-size: 14px;
}

.btn-acordeon {
    text-decoration: none;
    color: #495057;
    display: flex;
    justify-content: space-between;
}

.card-header-exp {
    border-radius: 0.2rem;
    border: 1px solid #ced4da;
}

.icon-down {
    font-weight: 900;
    font-size: 10px;
}
</style>