<template>
    <div class="pc">
        <NavbarGuest :translator="translator" :user="user" :booking="booking" />


        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->
        <section v-else>

            <div class="container mt-4">
                <div class="d-flex align-content-center justify-content-between">
                    <h1>{{ translator.reserva }}</h1>
                </div>
            </div>
            <iframe v-if="empresa != '' && user != ''"
                :src="`https://tpv.icnea.net/experiencies.aspx?sup=1&ord=${reserva}&emp=${empresa}&idi=${user.user_language}`"
                frameborder="0" onload="parent.scrollTo(0,0)"></iframe>
        </section>
    </div>
</template>


<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: 'GuestReservation',
    computed: {
        ...mapState("utils", ['user', 'translator', 'empresa']),
        ...mapState("guest", ['booking']),
    },
    components: {
        NavbarGuest,
    },
    async created() {
        this.isPreLoad = true
        this.translator != '' ? null : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        await this.$store.dispatch("utils/getEmpresa", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        setTimeout(() => {
            this.isPreLoad = false
        }, 2000)
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        },
    },

    data() {
        return {
            isPreLoad: true,
            reserva: this.$route.params.reserva,
        }
    }
}
</script>


<style scoped>
iframe {
    min-height: 100vh;
    width: 100%;
}
</style>