<template>
    <div class="reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->
        <div v-else>
            <NavbarGuest :translator="translator" :user="user" :booking="booking" />

            <div class="container pt-4">
                <div v-html='terms'></div>   
            </div>
        </div>
    </div>
</template>

<script>
    import NavbarGuest from "@/components/NavbarGuest.vue";
    import { mapState } from "vuex";

    export default {
        name: "GuestTerms",
        components: {
            NavbarGuest,
        },

        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("guest", ['terms', 'booking',])
        },

        watch: {
            translator() {
                this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.$store.dispatch("guest/getTerms", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            },
            terms() {
                this.isPreLoad = false;
            },
        },

        async created() {
            try {
                this.isPreLoad = true;
                if (this.translator) {
                    await this.$store.dispatch("guest/getTerms", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                    this.isPreLoad = false;
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }
            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                isPreLoad: false,
                numInstruction: null
            };
        }
    }
</script>
