<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <div v-else>

            <NavbarGuest :translator="translator" :user="user" :booking="booking" />

            <div class="container mt-5">
                <div class="d-flex justify-content-between">
                    <h1>
                        <span>
                            <i class="fal fa-bell-concierge pr-1"></i>
                        </span>
                        <span v-cloak> {{ translator.suplements }}</span>
                    </h1>
                    <div class="pt-1" v-cloak>

                    </div>

                </div>

                <section v-if="suplements[0].length > 0" class="d-flex flex-column mt-3">

                    <article v-for="(sup, i) in suplements[0]" :key="i" class="card mb-3">
                        <div v-if="parseInt(sup.supplement_amount) !== 0 || (parseInt(sup.supplement_amount) === 0 && parseInt(sup.supplement_price) === 0 && sup.supplement_cancellable == 'true')"
                            class="ribbon">
                            <span>{{ translator.reservats }}</span>
                        </div>

                        <div class="row no-gutters">


                            <div class="col-4 col-md-2">
                                <img width="120" height="120" :src="sup.supplement_image"
                                    :alt="sup.supplement_headline">
                            </div>
                            <div class="col-8 col-md-10 align-self-center">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between flex-wrap">


                                        <div class="d-flex flex-column" style="min-width: 120px;">
                                            <h2 class="card-title titular">{{ sup.supplement_headline }}</h2>
                                            <p>
                                                <span v-if="parseInt(sup.supplement_amount) !== 0">{{
                                                    translator.preutotal }}: </span>

                                                <span v-if="parseInt(sup.supplement_amount) === 0"
                                                    class="card-title titular">

                                                    <span v-if="isNaN(parseFloat(sup.supplement_price))">
                                                        {{ sup.supplement_price }}
                                                    </span>
                                                    <span v-else>

                                                        <span
                                                            :class="parseInt(sup.supplement_amount) === 0 && parseInt(sup.supplement_price) === 0 ? 'd-none' : ''">
                                                            {{ sup.supplement_price | formatPrice }}{{ currency }}
                                                        </span>
                                                    </span>
                                                </span>
                                                <span v-else class="card-title titular">
                                                    {{ sup.supplement_amount | formatPrice }}{{ currency }}
                                                </span>

                                                <span
                                                    v-if="(parseInt(sup.supplement_amount) === 0 && !isNaN(parseFloat(sup.supplement_price)))"
                                                    :class="parseInt(sup.supplement_amount) === 0 && parseInt(sup.supplement_price) === 0 ? 'd-none' : ''">
                                                    / {{ String(sup.supplement_group).replace('0 ', '') }}
                                                </span>
                                                <span class="titular"
                                                    v-if="(parseInt(sup.supplement_amount) === 0 && parseInt(sup.supplement_price) === 0 && sup.supplement_cancellable == 'true')">
                                                    {{ translator.gratuit }}
                                                </span>

                                            </p>
                                        </div>

                                        <button
                                            v-if="(parseInt(sup.supplement_amount) !== 0 || (parseInt(sup.supplement_amount) === 0 && parseInt(sup.supplement_price) === 0)) && sup.supplement_cancellable == 'true'"
                                            @click="postSuplementsAnular(sup.supplement_id)" class="btn btn-danger">{{
                                                translator.cancelar }}</button>

                                        <div v-else class="btn-group" role="group" aria-label="input suplements">
                                            <button type="button" class="btn-operacion btn btn-secondary"
                                                @click="decrement(sup.supplement_id)"
                                                :disabled="cart[sup.supplement_id] <= 0">-</button>
                                            <input type="text" class="form-control supplement-input"
                                                :value="cart[sup.supplement_id] || 0" readonly>
                                            <button type="button" class="btn-operacion btn btn-secondary"
                                                @click="increment(sup.supplement_id)"
                                                :disabled="cart[sup.supplement_id] >= sup.supplement_maximum">+</button>
                                        </div>
                                        <!-- supplement_cancellable -->

                                    </div>

                                </div>
                            </div>
                        </div>
                    </article>
                </section>

                <section v-else>
                    <h1 class="text-center">
                        No {{ translator.suplements }}
                    </h1>
                </section>
                <div class="d-flex align-items-end flex-column  flex-md-row">
                    <button class="btn btn-secondary boton" @click="postSuplementsAfegir">
                        {{ translator.afegirsuplements }}
                    </button>

                    <router-link
                        :to="!isAddSupplements ? '' : { name: 'GuestPayment', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }"
                        class="btn btn-secondary boton mt-2 ml-md-2"
                        :class="{ 'disabled': !isAddSupplements || parseInt(bookingPayments.pending_payment) == 0 }"
                        role="button"
                        @click.prevent="(!isAddSupplements || parseInt(bookingPayments.pending_payment) == 0) && $router.push({ name: 'GuestPayment', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } })">
                        <span class="display">{{ translator.pagament }}</span>
                    </router-link>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: 'GuestSupplements',

    components: {
        NavbarGuest,
    },

    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'suplements', 'confirmAddSuplementsToCart', 'bookingPayments']),

        onlyCountActivitysCivitatis() {
            return this.activitiesList.filter(activity => !activity.activity_id.startsWith('I')).length;
        }

    },
    async created() {
        //this.loadCartFromLocalStorage()
        this.distance = this.distanceStore;
        try {
            if (this.translator) {
                this.isPreLoad = true;
                await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                await this.$store.dispatch("guest/getSuplements", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            } else {
                this.isPreLoad = true;
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
        } catch (error) {
            console.error(error);
        }
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getSuplements", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        },
        suplements(newQ) {
            this.isPreLoad = false;
            this.isAddSupplements = false;
            for (let sup of newQ[0]) {
                if (parseInt(sup.supplement_amount)) {
                    this.isAddSupplements = true;
                }
            }
        },
        booking(newQ) {
            this.currency = newQ.currency
        }

    },

    data() {
        return {
            isPreLoad: true,
            supplements: [],
            cart: {},
            currency: '€',
            isAddSupplements: false
        };
    },

    methods: {
        increment(supplementId) {
            if (!this.cart[supplementId]) {
                this.$set(this.cart, supplementId, 0)
            }
            this.cart[supplementId]++
            //this.saveCartToLocalStorage()
        },
        decrement(supplementId) {
            if (this.cart[supplementId] > 0) {
                this.cart[supplementId]--
                //this.saveCartToLocalStorage()
            }
        },
        // saveCartToLocalStorage() {
        //     localStorage.setItem('cart', JSON.stringify(this.cart))
        // },
        // loadCartFromLocalStorage() {
        //     const savedCart = localStorage.getItem('cart')
        //     if (savedCart) {
        //         this.cart = JSON.parse(savedCart)
        //     }
        // },
        async postSuplementsAfegir() {
            const delay = (t) => new Promise((r) => setTimeout(r, t))
            for (const [key, value] of Object.entries(this.cart)) {
                if (parseInt(value) != 0) {
                    await this.$store.dispatch("guest/postSuplementsAfegir", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, suplementID: parseInt(key), pax: parseInt(value) });
                    await delay(200)
                }
            }

            if (this.confirmAddSuplementsToCart) {
                this.$toast.success(this.translator.afegit, { duration: 5000 });
                await this.$store.dispatch("guest/getSuplements", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            } else {
                this.$toast.error('Error');
            }
        },
        async postSuplementsAnular(suplementID) {
            await this.$store.dispatch("guest/postSuplementsAnular", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, suplementID });
        },

    },
    filters: {
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        },

        formatPrice(value) {
            if (isNaN(value)) {
                return value;
            }
            let formattedNumber = parseFloat(value).toFixed(2);
            formattedNumber.endsWith('.00') ? formattedNumber = formattedNumber.slice(0, -3) : null;
            return formattedNumber;
        },
        capitalizeSentences(value) {
            if (!value) return "";
            return value.replace(/(?:^|\.\s|\.\s)([a-z])/g, (match, p1) => p1.toUpperCase());
        }
    },

}
</script>

<style scoped>
.pc {
    background-color: transparent;
}

article {
    padding-bottom: 10px;
    border-bottom: 2px solid #eeeeee;
}

img {
    object-fit: cover;
}

.titular {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 700;
}

@media (max-width: 767) {
    .boton {
        max-width: 200px;
    }
}

.btn-operacion {
    width: 32px;
    height: 32px;

}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.supplement-input {
    width: 42px;
    height: 32px;
    background-color: transparent;
}

.ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 105px;
    height: 105px;
    text-align: left;
}

.ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #212121;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 130px;
    display: block;
    background: var(--naranja);
    background: linear-gradient(#ccc 0%, #ccc 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 24px;
    left: -27px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-right: 3px solid var(--naranja);
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid var(--naranja);
}

.ribbon span::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-left: 3px solid var(--naranja);
    border-bottom: 3px solid transparent;
    border-top: 3px solid var(--naranja);
}
</style>