<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->

        <div v-if="cardPaymentHref === undefined">

            <NavbarGuest :translator="translator" :user="user" :booking="booking" />

            <div v-if="bookingPayments.paid" class="container mt-4">

                <h1 v-cloak class="mb-4">
                    <span>
                        <i class="fal fa-wallet pr-1"></i>
                    </span>
                    {{ translator.gestiopagements }}
                </h1>

                <div class="row pb-3">
                    <div class="col-6" style="padding-right: 5px;">
                        <div class="pagado">
                            <label v-cloak>{{ translator.pagat }}</label>
                            <span v-cloak>{{ bookingPayments.paid }} {{ bookingPayments.currency }}</span>
                        </div>
                    </div>
                    <div class="col-6" style="padding-left: 5px;">
                        <div class="por-pagar">
                            <label v-cloak>{{ translator.perpagar }}</label>
                            <span :class="{ 'paiment-complete': (bookingPayments.pending_payment == 0) ? true : false }"
                                v-cloak>{{ bookingPayments.pending_payment }} {{ bookingPayments.currency }}</span>
                        </div>
                    </div>
                </div>

                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th v-cloak>{{ translator.concepte }}</th>
                            <th class="text-right" v-cloak>{{ translator.import }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(extra, index) in bookingPayments.detail" :key="index">
                            <td class="pl-0" v-cloak>{{ extra.text }}</td>
                            <td class="quantitat pr-0">{{ extra.import }}</td>
                        </tr>
                        <tr>
                            <td class="pl-0" v-cloak>{{ translator.totalestada }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.stay_amount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.cleaning_fee != 0" v-cloak>
                            <td class="pl-0">{{ translator.carrecneteja }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.cleaning_fee }}</td>
                        </tr>
                        <tr v-if="bookingPayments.booking_fee != 0" v-cloak>
                            <td class="pl-0">{{ translator.carrecgestio }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.booking_fee }}</td>
                        </tr>
                        <tr v-if="bookingPayments.checkin_fee != 0" v-cloak>
                            <td class="pl-0">{{ translator.carrecentrada }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.checkin_fee }}</td>
                        </tr>
                        <tr v-if="bookingPayments.shortstay_fee != 0" v-cloak>
                            <td class="pl-0">{{ translator.carreccurtaestada }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.shortstay_fee }}</td>
                        </tr>
                        <tr v-if="bookingPayments.earlybooking_discount != 0" v-cloak>
                            <td class="pl-0">{{ translator.descompteanticipat }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.earlybooking_discount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.longstay_discount != 0" v-cloak>
                            <td class="pl-0">{{ translator.descomptellargaestada }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.longstay_discount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.lastminute_discount != 0" v-cloak>
                            <td class="pl-0">{{ translator.descomptelastminute }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.lastminute_discount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.standard_discount != 0" v-cloak>
                            <td class="pl-0">{{ translator.descompte }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.standard_discount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.promotion_discount != 0" v-cloak>
                            <td class="pl-0">{{ translator.descomptepromocio }}</td>
                            <td class="quantitat pr-0">{{ bookingPayments.promotion_discount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.tourist_tax != 0">
                            <td class="pl-0" v-cloak>{{ translator.taxaturistica }}</td>
                            <td class="quantitat pr-0" v-cloak>{{ bookingPayments.tourist_tax }}</td>
                        </tr>
                        <tr v-if="bookingPayments.total_amount != 0">
                            <td class="total pl-0"
                                :class="{ 'correcto': (bookingPayments.pending_payment == 0) ? true : false }" v-cloak>
                                {{
                                    translator.total }} <i v-if="bookingPayments.pending_payment == '0.00'"
                                    class="pl-1 fas fa-check-circle"></i></td>
                            <td class="total quantitat pr-0"
                                :class="{ 'correcto': (bookingPayments.pending_payment == 0) ? true : false }" v-cloak>
                                {{
                                    bookingPayments.total_amount }}</td>
                        </tr>
                        <tr v-if="bookingPayments.security_deposit != 0" v-cloak>
                            <td class="total deposit pl-0"
                                :class="{ 'correcto': (bookingPayments.deposit_paid == 'true') ? true : false }">{{
                                    translator.diposit }} <i v-if="bookingPayments.deposit_paid == 'true'"
                                    class="pl-1 fas fa-check-circle"></i></td>
                            <td class="total quantitat pr-0"
                                :class="{ 'correcto': (bookingPayments.deposit_paid == 'true') ? true : false }">{{
                                    bookingPayments.security_deposit }}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Alert para España primero añadir húespedes -->
                <!-- <div v-if="booking.format_hostes == 'ES' &&  booking.registered_guests < parseInt(booking.sumAdultsChildren)" class="alert alert-info" role="alert">
                    Para_proceder_al_pago_debe introducir primero_todos_los_huéspedes.
                </div> -->
                <!-- Pago reserva -->
                <!-- Validacion: húespedes registrados y españoles -->
                <!-- <section v-if="bookingPayments.pending_payment != 0 && !(booking.format_hostes == 'ES' &&  booking.registered_guests < parseInt(booking.sumAdultsChildren))"> -->
                <section
                    v-if="bookingPayments.pending_payment != 0 && (bookingPayments.bank_transfer_payment === 'true' || bookingPayments.credit_card_payment === 'true' || bookingPayments.paypal_payment === 'true')">
                    <h4 class="mt-5 border-bottom pb-2 mb-2" style="font-size: 16px;">{{ translator.pagarreserva }}:
                    </h4>

                    <!-- Acordeón transferencia bancaria -->
                    <div class="accordion" id="accordionTransfer"
                        v-if="bookingPayments.bank_transfer_payment === 'true' && bookingPayments.pending_payment != 0">
                        <div class="card">
                            <div id="headingOne">
                                <button @click="arrowContract = !arrowContract"
                                    style="padding-left: 0; font-size: 14px;" class="btn" type="button"
                                    data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                    aria-controls="collapseOne">
                                    {{ translator.transferencia }}
                                    <i class="fal fa-chevron-up fa-sm pl-1" v-if="arrowContract"></i>
                                    <i class="fal fa-chevron-right fa-sm pl-1" v-else></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordionTransfer">
                                <ul v-if="bookingPayments.bank_transfer_payment === 'true' && bookingPayments.pending_payment != 0"
                                    style="list-style: outside none none; padding: 15px 20px; border: 1px solid #ced4da; border-radius: 0.25rem;">
                                    <li v-if="bookingPayments.bank_details.bank_name != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.banc }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_name }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_holder != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.titular }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_holder }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_account != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.compte }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_account }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_bic != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.BIC }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_bic }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_address != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.adreca }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_address }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_city != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.poble }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_city }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_zip != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.dip }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_zip }}
                                    </li>
                                    <li v-if="bookingPayments.bank_details.bank_country != ''">
                                        <span
                                            style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                            {{ translator.pais }}
                                        </span>
                                        {{ bookingPayments.bank_details.bank_country }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Fin acordeón transferencia bancaria -->

                    <div v-if="bookingPayments.credit_card_payment === 'true' && bookingPayments.pending_payment != 0">
                        <button class="btn" style="padding-left: 0; font-size: 14px;"
                            @click.prevent.self="cardPayment(bookingPayments.pending_payment, false, false)" v-cloak>{{
                                translator.pertargeta }} <i class="fal fa-chevron-right fa-sm pl-1"></i></button>
                    </div>
                    <div v-if="bookingPayments.paypal_payment === 'true' && bookingPayments.pending_payment != 0">
                        <button class="btn" style="padding-left: 0; font-size: 14px;"
                            @click.prevent.self="cardPayment(bookingPayments.pending_payment, false, true)" v-cloak>{{
                                translator.paypal }} <i class="fal fa-chevron-right fa-sm pl-1"></i></button>
                    </div>
                </section>
                <!-- Fin pago resera -->

                <!-- Pago depósito -->
                <!-- Validacion: húespedes registrados y españoles -->
                <!-- <section v-if="bookingPayments.deposit_paid == 'false' && bookingPayments.security_deposit != 0 && !(booking.format_hostes == 'ES' &&  booking.registered_guests < parseInt(booking.sumAdultsChildren))"> -->

                <section
                    v-if="bookingPayments.deposit_paid == 'false' && bookingPayments.security_deposit != 0 && ((bookingPayments.credit_card_payment === 'true' && booking.deposit_type === 'creditcard') || (bookingPayments.credit_card_payment === 'true' && booking.deposit_type === 'preauthorization') || booking.deposit_type === 'banktransfer' || booking.deposit_type == 'swikly')">
                    <h4 class="mt-5 border-bottom pb-2 mb-2" style="font-size: 16px;">{{ translator.pagardiposit }}:
                    </h4>

                    <!-- Pago con targeta o Preautorización targeta -->
                    <div v-if="booking.deposit_type == 'creditcard'" class="mt-2">
                        <button v-if="bookingPayments.credit_card_payment === 'true'" class="btn"
                            style="padding-left: 0; font-size: 14px;"
                            @click.prevent="cardPayment(bookingPayments.security_deposit, true, false)" v-cloak>
                            {{ translator.pertargeta }} <i class="fal fa-chevron-right fa-sm pl-1"></i>
                        </button>
                    </div>

                    <!-- Pago swikly -->
                    <div v-if="booking.deposit_type == 'swikly' && booking.deposit_url != ''" class="mt-2">
                        <a :href="booking.deposit_url" target="_blank" class="btn"
                            style="padding-left: 0; font-size: 14px;" v-cloak>
                            {{ translator.pertargeta }} <i class="fal fa-chevron-right fa-sm pl-1"></i>
                        </a>
                    </div>

                    <!-- Preautorización targeta -->
                    <div v-if="booking.deposit_type == 'preauthorization'" class="mt-2">
                        <button :disabled="this.isPreauthorizationDayCorrect()"
                            v-if="bookingPayments.credit_card_payment === 'true'" class="btn"
                            style="padding-left: 0; font-size: 14px;"
                            @click.prevent="cardPayment(bookingPayments.security_deposit, true, false)" v-cloak>
                            <span>{{ translator.preautoritzar }} </span>
                            <i class="fal fa-chevron-right fa-sm pl-1"></i>
                        </button>


                        <small v-if="this.isPreauthorizationDayCorrect()" style="color:darkred;"><br>
                            <span v-if="parseInt(booking.deposit_days_before_arrival) === 0">
                                {{ translator.abansarrivada0 }}
                            </span>
                            <span v-else-if="parseInt(booking.deposit_days_before_arrival) === 1">
                                {{ translator.abansarrivada1 }}
                            </span>
                            <span v-else>
                                {{ String(translator.abansarrivada2).replace('$',
                                    booking.deposit_days_before_arrival) }}
                            </span>
                        </small>

                    </div>

                    <!-- Pago por tranferencia -->
                    <div v-if="booking.deposit_type == 'banktransfer'">
                        <!-- Acordeón transferencia bancaria -->
                        <div class="accordion" id="accordionDepositTransfer"
                            v-if="bookingPayments.bank_transfer_payment === 'true'">
                            <div class="card">
                                <div id="depositHeadingOne">
                                    <button @click="arrowDeposit = !arrowDeposit"
                                        style="padding-left: 0; font-size: 14px;" class="btn" type="button"
                                        data-toggle="collapse" data-target="#collapseDepositOne" aria-expanded="false"
                                        aria-controls="collapseDepositOne">
                                        {{ translator.transferencia }}
                                        <i class="fal fa-chevron-up fa-sm pl-1" v-if="arrowContract"></i>
                                        <i class="fal fa-chevron-right fa-sm pl-1" v-else></i>
                                    </button>
                                </div>
                                <div id="collapseDepositOne" class="collapse" aria-labelledby="depositHeadingOne"
                                    data-parent="#accordionDepositTransfer">
                                    <ul v-if="bookingPayments.bank_transfer_payment === 'true'"
                                        style="list-style: outside none none; padding: 15px 20px; border: 1px solid #ced4da; border-radius: 0.25rem;">
                                        <li v-if="bookingPayments.bank_details.bank_name != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.banc }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_name }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_holder != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.titular }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_holder }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_account != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.compte }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_account }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_bic != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.BIC }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_bic }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_address != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.adreca }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_address }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_city != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.poble }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_city }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_zip != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.dip }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_zip }}
                                        </li>
                                        <li v-if="bookingPayments.bank_details.bank_country != ''">
                                            <span
                                                style="display: block; text-transform: uppercase; font-size: 11px; opacity: 0.7; padding-top: 10px; line-height: 10px;">
                                                {{ translator.pais }}
                                            </span>
                                            {{ bookingPayments.bank_details.bank_country }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Fin acordeón transferencia bancaria -->
                    </div>

                </section>
                <!-- Fin pago depósito -->


            </div>
        </div>



        <!-- Navegaciones footer -->
        <div class="cotainer d-flex justify-content-between">
            <div>
                <a v-if="isMarcaBlancaActive"
                    :href="`https://app.icnea.net/guests?id=${String(this.$route.query.id).replace(/[ ]/g, '+')}`"
                    class="nav-link" :class="huespedesCompleto ? 'todo-ok' : 'falta-info'">
                    <span class="display-responsive"><i class="fa-light fa-chevron-left"></i> {{ translator.hostes
                        }}</span>
                </a>
                <router-link v-else
                    :to="{ name: 'GuestGuests', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }"
                    class="nav-link" :class="huespedesCompleto ? 'todo-ok' : 'falta-info'">
                    <span class="display-responsive"><i class="fa-light fa-chevron-left"></i> {{ translator.hostes
                        }}</span>
                </router-link>
            </div>

            <div>
                <a v-if="isMarcaBlancaActive && booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00' || booking.mandatory_deposit_guarantee_at_check_in == 'false')"
                    :href="`https://${user.user_app}/contract?id=${String(this.$route.query.id).replace(/[ ]/g, '+')}`"
                    class="nav-link todo-ok">{{ translator.contracte }} <i class="fa-light fa-chevron-right"></i></a>
                <router-link
                    v-else-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00' || booking.mandatory_deposit_guarantee_at_check_in == 'false')"
                    class="nav-link todo-ok"
                    :to="{ name: 'GuestContract', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    {{ translator.contracte }} <i class="fa-light fa-chevron-right"></i>
                </router-link>
                <a v-else href="#" class="nav-link disabled">{{ translator.contracte }} <i
                        class="fa-light fa-chevron-right"></i></a>
            </div>
        </div>

    </div>
</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: "GuestPayment",
    components: {
        NavbarGuest,
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ["booking", "bookingPayments", "bookingGuests"]),

    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        },
        booking() {
            this.isPreLoad = false;
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : '';
                guest._input_empty ? this.isFilledFields = false : null;
                !guest.document_image ? this.is_allPhotoDocuments = false : null;
                !guest.guest_photo && guest._isAdult ? this.is_allSelfiGuests = false : null;
            });
            firms >= adults ? this.controlAdultWithSign = true : '';
            console.log('firms >= adults', this.controlAdultWithSign)
        },
    },

    data() {
        return {
            isPreLoad: false,
            isPreauthorizationDay: false,
            cardPaymentHref: undefined,
            arrowContract: false,
            arrowDeposit: false,
            formCash: {
                date: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
                import: '',
            },
            isMarcaBlancaActive: false,
            controlAdultWithSign: false,
            is_allSelfiGuests: true,
            is_allPhotoDocuments: true,
            is_allAnversoDocument: true,
            isFilledFields: true,
        };
    },

    async created() {
        try {
            this.isPreLoad = true;
            (window.location.host == 'app.icnea.net' || window.location.host == 'xapp.icnea.net' || window.location.host == 'localhost:8080') && this.user.user_app == 'app.icnea.net' ? this.isMarcaBlancaActive = false : this.isMarcaBlancaActive = true;
            if (this.translator) {
                await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                await this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }

        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        huespedesCompleto() {
            if ((
                (this.booking.mandatory_guests_at_check_in === 'true' && this.booking._registered_adults_only_completed) ||
                this.booking.mandatory_guests_at_check_in === 'false'
            ) &&
                (
                    (this.booking.mandatory_signatures_contract === 'true' && this.controlAdultWithSign) ||
                    this.booking.mandatory_signatures_contract === 'false'
                ) &&
                (
                    (this.booking.mandatory_guests_contract === 'true' && this.isFilledFields && this.booking._registered_adults_only_completed) ||
                    this.booking.mandatory_guests_contract === 'false'
                ) &&
                (
                    this.booking.mandatory_guests_at_check_in == 'false' ||
                    (
                        (this.booking.mandatory_guests_at_check_in === 'true') &&
                        ((this.booking.visible_selfie === 'true' && this.is_allSelfiGuests) || this.booking.visible_selfie === 'false') &&
                        ((this.booking.visible_passport === 'true' && this.is_allPhotoDocuments) || this.booking.visible_passport === 'false') &&
                        ((this.booking.visible_document === 'true' && this.is_allAnversoDocument) || this.booking.visible_document === 'false')
                    )
                )) {

                return true
            } else {
                return false
            }
        },
        async cardPayment(pending_payment, diposit, paypal) {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch("guest/postCardPayment", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, cobrar: parseFloat(pending_payment).toFixed(2), diposit, paypal });
            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },
        async cashPayment() {
            try {
                this.isPreLoad = true;
                if (this.formCash.import.length > 0) {
                    await this.$store.dispatch("guest/postCashPayment", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, importPagar: this.formCash.import });
                    this.$toast.success(this.translator.pagat);
                    this.closeNav();
                    this.formCash.import = '';
                } else {
                    this.$toast.error(this.translator.importobligatori);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },
        openNav() {
            document.getElementById("cash").style.width = "100%";
        },
        closeNav() {
            document.getElementById("cash").style.width = "0";
        },

        isPreauthorizationDayCorrect() {
            const { arrival_date, deposit_days_before_arrival } = this.booking;
            const fechaLlegada = new Date(arrival_date);
            const fechaPreautorizada = new Date();

            fechaLlegada.setDate(fechaLlegada.getDate() - parseInt(deposit_days_before_arrival));
            if (fechaLlegada >= fechaPreautorizada) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>

<style>
.correcto {
    color: var(--colores-verde-check) !important;
}

.card {
    background: transparent;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: var(--white);
    overflow-x: hidden;
    transition: 0.5s;
}

.sidenav a {
    display: block;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.paiment-complete {
    color: var(--colores-gris-medio);
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.todo-ok {
    color: var(--colores-verde-check) !important;
}

.falta-info {
    color: var(--colores-amarillo-2) !important;
}
</style>