<template>
    <div>

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <div class="container my-5">

            <div v-for="(ord, index) in shoppingCart" :key="index" class="card mb-3">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <img :src="ord.activity_img" :alt="ord.activity_headline" class="img-fluid"
                            onerror="this.onerror=null;this.src='../../../public/img/preview.png'">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h5 class="card-title">{{ ord.activity_headline }}</h5>
                                <i @click="removeItems(ord.activity_hash)" class="fa-light fa-trash p-2"
                                    style="cursor:pointer;"></i>
                            </div>
                            <div class="card-text" v-html="ord.activity_summary"></div>
                            <div class="text-right">
                                {{ translator.preu }}: {{ ord.activity_total_price }}{{ booking.currency }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                {{ translator.total }}: {{ totalOrder }}{{ booking.currency }}
            </div>

            <div class="d-flex ">
                <router-link
                    :to="{ name: 'GuestActivities', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }"
                    class="btn btn-secondary mx-2">{{ translator.seguirreservant }}</router-link>
                <button @click="bookNow" class="btn btn-secondary mx-2">
                    <span v-if="!isClickInBooking">
                        {{ translator.tramitarcomanda }}
                    </span>
                    <span v-else>
                        <div class="preload-wrapper"></div>
                        <div class="preload-dots">
                            <div class="hollow-dots-spinner" style="margin: auto;">
                                <div class=" dot" style="border: calc(15px / 5) solid #fff;"></div>
                                <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                                <div class="dot" style="border: calc(15px / 5) solid #fff;"></div>
                            </div>
                        </div>
                    </span>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarGuest from "@/components/NavbarGuest.vue";

export default {
    name: 'GuestOrderSummary',
    components: {
        NavbarGuest,
    },

    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking']),
    },
    async created() {
        if (this.translator) {
            this.isPreLoad = false;
            await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        } else {
            await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        }

        const store = localStorage.getItem('shoppingCart');
        store && JSON.parse(store).length > 0 ? this.shoppingCart = JSON.parse(store) : null;

    },
    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.isPreLoad = false;
        },
        shoppingCart() {
            this.totalOrder = 0;
            this.shoppingCart.forEach(d => {
                this.totalOrder += parseFloat(d.activity_total_price);
            })
        }
    },

    data() {
        return {
            isPreLoad: true,
            totalOrder: 0,
            shoppingCart: [],
            isClickInBooking: false,
        }
    },
    methods: {
        async removeItems(hash) {
            this.shoppingCart = this.shoppingCart.filter(article => article.activity_hash !== hash)
            localStorage.setItem("shoppingCart", JSON.stringify(this.shoppingCart));

            await this.$store.dispatch("guest/addShoppingCart", { numActivities: this.shoppingCart.length });
        },
        async bookNow() {
            this.isClickInBooking = true;
            setTimeout(() => {
                this.isClickInBooking = false;
            }, 4000)
            const activities = this.shoppingCart.map(d => {
                return d.activity;
            });

            await this.$store.dispatch("guest/getActivitatReservaCrear", {
                usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                reserva: this.user.user_booking,
                activities
            })
        }
    }
}
</script>