<template>
    <div class="pc guest center">
        <h1 class="text-center">{{ translator.reserva_finalitzada1 }}</h1>
        <h5 class="text-center mt-3">{{ translator.reserva_finalitzada2 }}</h5>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: "GuestExpired",
        computed: {
            ...mapState("utils", ["user", "translator"]),
        },
        async created() {
            try {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            }
        },
    };
</script>

<style scoped>
.center {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>