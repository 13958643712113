<template>
    <div class="reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->
        <div v-else>
            <NavbarGuest :translator="translator" :user="user" :booking="booking" />

            <div class="container pt-4">
                <div v-for="inst in instruccions" :key="inst.info_id" >
                    <div v-if="inst.info_id == numInstruction">
                        <div class="d-flex justify-content-between" >
                            <h1 class="mb-4">
                                <span> {{ inst.info_headline }}</span>
                            </h1>
                            <!--<div class="link-return" @click="$router.go(-1)">
                                <i class="fal fa-chevron-left"></i> {{ translator.tornar }}
                            </div>-->
                        </div>
                        <div v-html="parseInfoText(inst)" class="container-info"></div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import NavbarGuest from "@/components/NavbarGuest.vue";
    import { mapState } from "vuex";

    export default {
        name: "GuestInstructions",
        components: {
            NavbarGuest,
        },

        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("guest", ['booking', 'instruccions',]),

            info: function(text) {
                return String(text)
            }
        },

        watch: {
            translator() {
                this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.$store.dispatch("guest/getInstructions", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.numInstruction = this.$route.params.instruction;
            },
            instruccions() {
                this.isPreLoad = false;
            },
        },

        async created() {
            try {
                this.isPreLoad = true;
                if (this.translator) {
                    await this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                    await this.$store.dispatch("guest/getInstructions", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                    this.numInstruction = this.$route.params.instruction;
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }
            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                isPreLoad: false,
                numInstruction: null
            };
        },

        methods: {
            // parseInfoText(inst) {
            //     if (!inst.info_text) return "";
            //     if ( String(inst.info_text).includes('[market]') ) {
            //         this.isTitular = false;
            //         const url = this.booking.market_url;
            //         return inst.info_text.replace(/\[market\]/g, `<a href='${url}' class='btn btn-primary my-3' style='max-width: 300px;' target='_blank'>${inst.info_headline}</a>`);
            //     }
            //     return inst.info_text
            // },
            parseInfoText(inst) {
                if (!inst.info_text) return "";
                const pattern = /\{market}\{([^}]+)}/g;
                const url = this.booking.market_url;
                return inst.info_text.replace(pattern, (match, linkText) => {
                    return `<a href="${url}" class="btn btn-primary my-3" style="max-width: 300px;" target="_blank">${linkText}</a>`;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.link-return {
    color: var(--colores-azul-claro);
    cursor: pointer;
    &:hover {
        color: var(--azul-oscuro);
    }
}

.container-info /deep/ img {
    max-width: 100% !important;
}

@media (max-width: 497px) {
    .container-info /deep/ table {
        width: 100% !important;
    }
    
}
</style>