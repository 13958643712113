<template>
    <div class="reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <div class="container pt-4" v-if="booking.arrival_date">

            <h1 class="mb-4">
                <i class="fal fa-house-user pr-1"></i>
                <span v-cloak> {{ translator.dadesreserva }}</span>
            </h1>

            <div style="background: #fff; padding: 10px; border-radius: 4px;">
                <table class="table table-sm">
                    <tbody>
                        <tr>
                            <td class="border-0"><b>{{ translator.propietat }}</b></td>
                            <td class="border-0"><b>{{ booking.lodging_name }}</b></td>
                        </tr>
                        <tr>
                            <td>{{ translator.reserva }} ID</td>
                            <td>{{ booking.reservation }}</td>
                        </tr>
                        <tr>
                            <td>{{ translator.arribada }}</td>
                            <td>{{ booking.arrival_date | transformDate }} {{ booking.arrival_time }}<span v-if="booking.arrival_time != ''">h</span></td>
                        </tr>
                        <tr>
                            <td>{{ translator.sortida }}</td>
                            <td>{{ booking.departure_date | transformDate }} {{ booking.departure_time }}<span v-if="booking.departure_time != ''">h</span></td>
                        </tr>
                        <tr>
                            <td>{{ translator.nits }}</td>
                            <td>
                                <span v-if="booking.nights == 1" v-cloak>{{ booking.nights }} {{ translator.nit | toLower }}</span>
                                <span v-else v-cloak>{{ booking.nights }} {{ translator.nits | toLower }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ translator.persones }}</td>
                            <td>
                                {{ booking.adults }} {{ translator.adults | toLower }}
                                -
                                {{ booking.children }} {{ translator.nens | toLower }}
                                -
                                {{ booking.babies }} {{ translator.bebes | toLower }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ translator.origen }}</td>
                            <td>
                                {{ booking.agency_name }}
                                <span v-if="booking.agency_reservation">
                                    <br />
                                    {{ booking.agency_reservation }}
                                </span>
                            </td>
                        </tr>

                        <tr style="border-top: 2px solid #dee2e6;">
                            <td v-cloak>{{ translator.estada }}</td>
                            <td>{{ bookingPayments.stay_amount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.cleaning_fee != 0" v-cloak>
                            <td>{{ translator.carrecneteja }}</td>
                            <td>{{ bookingPayments.cleaning_fee }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.booking_fee != 0" v-cloak>
                            <td>{{ translator.carrecgestio }}</td>
                            <td>{{ bookingPayments.booking_fee }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.checkin_fee != 0" v-cloak>
                            <td>{{ translator.carrecgestio }}</td>
                            <td>{{ bookingPayments.checkin_fee }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.shortstay_fee != 0" v-cloak>
                            <td>{{ translator.carreccurtaestada }}</td>
                            <td>{{ bookingPayments.shortstay_fee }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.earlybooking_discount != 0" v-cloak>
                            <td>{{ translator.descompteanticipat }}</td>
                            <td>{{ bookingPayments.earlybooking_discount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.longstay_discount != 0" v-cloak>
                            <td>{{ translator.descomptellargaestada }}</td>
                            <td>{{ bookingPayments.longstay_discount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.lastminute_discount != 0" v-cloak>
                            <td>{{ translator.descomptelastminute }}</td>
                            <td>{{ bookingPayments.lastminute_discount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.standard_discount != 0" v-cloak>
                            <td>{{ translator.descompte }}</td>
                            <td>{{ bookingPayments.standard_discount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.promotion_discount != 0" v-cloak>
                            <td>{{ translator.descomptepromocio }}</td>
                            <td>{{ bookingPayments.promotion_discount }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.tourist_tax != 0">
                            <td v-cloak>{{ translator.taxaturistica }}</td>
                            <td v-cloak>{{ bookingPayments.tourist_tax }} {{ bookingPayments.currency }}</td>
                        </tr>
                        <tr v-if="bookingPayments.total_amount != 0">
                            <td v-cloak><b>{{ translator.total }}</b></td>
                            <td v-cloak><b>{{ bookingPayments.total_amount }} {{ bookingPayments.currency }}</b></td>
                        </tr>
                        <tr v-if="bookingPayments.security_deposit != 0" v-cloak>
                            <td>{{ translator.diposit }}</td>
                            <td>{{ bookingPayments.security_deposit }} {{ bookingPayments.currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import NavbarGuest from "@/components/NavbarGuest.vue";
    import { mapState } from "vuex";

    export default {
        name: "GuestInfo",
        components: {
            NavbarGuest,
        },

        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("guest", ['booking', 'bookingPayments']),
        },

        watch: {
            translator() {
                this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking  });
            },
            booking() {
                this.isPreLoad = false;
            },
        },

        async created() {
            try {
                this.isPreLoad = true;
                if (this.translator) {
                    await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking  });
                    await this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }
            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                isPreLoad: false
            };
        },

        filters: {
            toLower(value) {
                if (!value) return '';
                value = value.toString();
                return value.toLowerCase();
            },
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
            },
        }
    }
</script>

<style scoped>
</style>