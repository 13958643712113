<template>
    <div class="pc reserva">

        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <div class="container chat">
            <router-link :to="{ name: 'Guest', query: { id: $route.query.id }}">
                <h1 v-cloak>
                    <i class="fal fa-chevron-left"></i>
                    {{ translator.xat }}
                </h1>
            </router-link>

            <div class="d-flex" v-for="(m, index) in xatConversa" :key="index" :class="[m.chat_sender !== 'host' ? 'justify-content-end' : 'justify-content-start']">
                <div :class="[m.chat_sender !== 'host' ? 'host alert alert-light' : 'guest alert alert-success']">
                    <small class="text-black-50" style="font-size:12px;">{{ m.chat_date }}</small><br />
                    <span v-if="m.chat_sender == 'host'" class="text-dark" v-html="m.chat_text"></span>
                    <span v-else class="text-dark">{{ m.chat_text }}</span>
                </div>
            </div>
        </div>

        <div class="input-group contenedor-respuesta">
            <input type="text" v-model="inputChat" @keyup.enter="sendMessage" class="form-control border-left-0 border-right-0 rounded-0" aria-label="message" aria-describedby="send" placeholder="Escribe...">
            <div class="input-group-prepend">
                <button :class="[inputChat === '' ? 'disabled' : '']" class="btn btn-primary" type="button" @click.prevent="sendMessage"><i class="fad fa-paper-plane"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: "GuestChat",
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("guest", ["xatConversa"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("guest/getChat", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') })
            },
            xatConversa() {
                this.isPreLoad = false;
                setTimeout(()=> {
                    this.scrollToEnd();
                }, 10);
            },
        },

        data() {
            return {
                isPreLoad: false,
                inputChat: ''
            };
        },

        async created() {
            this.isPreLoad = true;
            try {
                this.translator
                    ? await this.$store.dispatch("guest/getChat", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },

        methods: {
            async sendMessage() {
                if (this.inputChat != '') {
                    try {
                        this.isPreLoad = true;
                        await this.$store.dispatch("guest/postSendMessage", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), text: this.inputChat, ip: '' });
                        this.inputChat = '';
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.isPreLoad = false;
                    }
                }
            },
            scrollToEnd() {    	
                window.scrollTo(0,document.body.scrollHeight)
            },
        }
    };
</script>

<style scoped>
    .contenedor-respuesta {
        max-width: 770px;
    }
    .reserva .container {
        padding-bottom: 40px;
    }
    .host {
    max-width: 70%;
    }
    .guest {
        max-width: 70%;
    }
</style>