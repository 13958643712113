<template>
    <div class="header-pc shadow-sm menu-guests">
        <nav class="navbar navbar-expand-lg nav-pc">
            <router-link :to="{ name: 'Guest', query: { id: paramID } }" class="navbar-brand">
                <div class="logo">
                    <img :src="translator.logo" width="100" />
                </div>
            </router-link>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fal fa-bars fa-lg"></i>
            </button>
            <div v-if="booking.reservation" class="collapse navbar-collapse justify-content-md-center"
                id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/guest.html?id=${paramID}`"
                            class="nav-link">{{ translator.inici }}</a>
                        <router-link v-else class="nav-link" :to="{ name: 'Guest', query: { id: paramID } }">
                            {{ translator.inici }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/info?id=${paramID}`"
                            class="nav-link">{{ translator.dadesreserva }}</a>
                        <router-link v-else :to="{ name: 'GuestInfo', query: { id: paramID } }" class="nav-link">
                            {{ translator.dadesreserva }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/details?id=${paramID}`"
                            class="nav-link">{{ translator.dadespersonals }}</a>
                        <router-link v-else :to="{ name: 'GuestDetails', query: { id: paramID } }" class="nav-link">
                            {{ translator.dadespersonals }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://app.icnea.net/guests?id=${paramID}`"
                            class="nav-link">
                            {{ translator.hostes }}
                        </a>
                        <router-link v-else :to="{ name: 'GuestGuests', query: { id: paramID } }" class="nav-link">
                            {{ translator.hostes }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/payment?id=${paramID}`"
                            class="nav-link">
                            <span class="display">{{ translator.pagament }}</span>
                            <span class="display-responsive">{{ translator.gestiopagements }}</span>
                        </a>
                        <router-link v-else :to="{ name: 'GuestPayment', query: { id: paramID } }" class="nav-link">
                            <span class="display">{{ translator.pagament }}</span>
                            <span class="display-responsive">{{ translator.gestiopagements }}</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/contract?id=${paramID}`"
                            class="nav-link">{{ translator.contracte }}</a>
                        <router-link v-else class="nav-link" :to="{ name: 'GuestContract', query: { id: paramID } }">
                            {{ translator.contracte }}
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="booking.smart_door_lock == 'true' || booking.key_box_code != ''">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/key?id=${paramID}`"
                            class="nav-link">{{ translator.clau }}</a>
                        <router-link v-else class="nav-link" :to="{ name: 'GuestKey', query: { id: paramID } }">
                            {{ translator.clau }}
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="booking.activities == 'true'">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/activities?id=${paramID}`"
                            class="nav-link">{{ translator.activitats }}</a>
                        <router-link v-else class="nav-link" :to="{ name: 'GuestActivities', query: { id: paramID } }">
                            {{ translator.activitats }}
                        </router-link>
                    </li>
                    <!-- <li v-if="booking.supplements == 'true'" class="nav-item">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/supplements?id=${paramID}`"
                            class="nav-link">{{ translator.suplements }}</a>
                        <router-link v-else class="nav-link" :to="{ name: 'GuestSupplements', query: { id: paramID } }">
                            {{ translator.suplements }}
                        </router-link>
                    </li> -->
                    <li class="nav-item" v-if="booking.activities == 'true' && shoppingCart > 0">
                        <a v-if="isMarcaBlancaActive" :href="`https://${user.user_app}/order-summary?id=${paramID}`"
                            class="nav-link">
                            <i class="fal fa-shopping-cart"></i>
                            <span
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                                {{ shoppingCart }}
                            </span>
                        </a>
                        <router-link v-else class="nav-link" :to="{ name: 'GuestOrderSumary', query: { id: paramID } }">
                            <i class="fal fa-shopping-cart"></i>
                            <span
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                                {{ shoppingCart }}
                            </span>
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'GuestReviews', query: { id: paramID } }">
                            *Reviews
                            {{ translator.testimonis }}
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                        <router-link :to="{ name: 'Home' }" class="nav-link">
                            Logout
                        </router-link>
                    </li> -->
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'NavbarGuest',
    props: ['user', 'translator', 'booking'],

    computed: {
        ...mapState("guest", ['smartKeys', 'shoppingCart']),
    },

    created() {
        // Marca blanca
        this.user.user_app == 'app.icnea.net' ? null : this.isMarcaBlancaActive = true;

        let param = ''
        this.$route.query.id != undefined ? param = String(this.$route.query.id).replace(/[ ]/g, '+') : param = String(this.$route.params.id).replace(/[ ]/g, '+');
        this.paramID = String(param)

        //Comprobación de número de actividades en carrito
        const store = localStorage.getItem('shoppingCart');
        store && JSON.parse(store).length > 0 ? this.$store.dispatch("guest/addShoppingCart", { numActivities: JSON.parse(store).length }) : null;
    },
    data() {
        return {
            paramID: null,
            isMarcaBlancaActive: false,
        };
    },
};
</script>

<style>
.logo img {
    max-height: 35px;
    width: auto;
}

.menu-guests .display-responsive {
    display: block;
}

.menu-guests .display {
    display: none;
}

@media screen and (min-width: 768px) {
    .menu-guests .display-responsive {
        display: none;
    }

    .menu-guests .display {
        display: block;
    }
}
</style>